<script>
  export let scoped,
    declineError = null,
    showingDeclinePopUp = false;
  import { clearSession } from "../../utils.js";
  import { goto, redirect } from "@roxi/routify";

  import OrderSummary from "../../components/OrderSummary.svelte";
  import { createOrder } from "../../payment/utils.js";
  import StripeDeclinePopUp from "../../components/stripe/StripeDeclinePopUp.svelte";
  import Loader from "../../components/Loader.svelte";

  const { countries, countryCode, price, isOnline, session, accessibleStages } =
    scoped;

  const orderData = {
    price,
    customerDetails: $session.customerDetails,
    countryCode,
  };
  const accessible = accessibleStages.includes("payment");
  if (!accessible) $redirect("./details");

  async function confirmOrder() {
    startLoading();
    let response = await createOrder({ ...orderData, method: "" });
    await response;
    let order = response.order;
    if (order) {
      clearOut(order);
    } else {
      stopLoading();
      if (error) showStripeDeclinePopUp(error);
    }
  }

  let isLoading = false;
  function startLoading() {
    isLoading = true;
  }

  function stopLoading() {
    isLoading = false;
  }

  function showStripeDeclinePopUp(error) {
    declineError = error;
    showingDeclinePopUp = true;
  }

  function clearOut(order) {
    try {
      clearSession($session);
    } finally {
      const search = new URLSearchParams(
        [["orderID", order.id], isOnline && ["online", 1]].filter(Boolean)
      );

      $goto(`/success/touchstone?${search}`);
    }
  }
</script>

{#if accessible}
  <OrderSummary {countries} {countryCode} {price} {isOnline} />
  <form on:submit|preventDefault={confirmOrder} method="post">
    <br />
    <div class="pay-now">
      <button class="btn btn--primary" style="margin-left: 38%;"
        >Confirm Order</button
      >
    </div>
  </form>

  <StripeDeclinePopUp
    bind:visible={showingDeclinePopUp}
    error={declineError}
    region={orderData.price.region}
  />

  <Loader visible={isLoading}>
    <div class="processing">Please wait ...</div>
  </Loader>
{/if}

<style>
  .processing {
    font-size: 52pt;
    font-weight: bold;
    color: white;
    text-shadow: 0 0 5px black;
  }
</style>

<script>
  export let price;

  import PriceFormatted from "./PriceFormatted.svelte";

  let value;
  $: value = price ? Number(price.value) : null;

  let monthly;
  $: monthly = price && price.variant.code === "monthly";

  let numberOfPayments;
  $: numberOfPayments = price && price.variant.number_of_payments;

  let companionCoursePrice;
  $: {
    if (price) {
      companionCoursePrice = price.currency_code == "GBP" ? 120 : 140;
    }
  }
</script>

<div class="price" class:monthly>
  {#if value && price}
    <div style="display: flex">
      <p>
        <strong
          ><PriceFormatted {value} currency={price.currency_code} /></strong
        >
      </p>
    </div>
    {#if monthly}
      <em class="number-of-payments">in {numberOfPayments} payments</em>
    {/if}
  {/if}
</div>
<div class="price flex-mode" style="padding-left: 0px;">
  <strong><PriceFormatted value="0.00" /></strong>
</div>

<style>
  @import "../styles/variables.css";

  .price {
    text-align: center;
    font-size: 30px;
    line-height: 30px;
    text-transform: uppercase;
    background: var(--tertiary);
    margin-left: var(--baseline);
    border-radius: 4px;
  }

  .price.monthly {
    font-size: 20px;
    line-height: 20px;
  }

  .price em {
    text-transform: none;
    font-size: 11px;
    display: block;
    line-height: 15px;
  }

  .flex-mode {
    display: inline-flex;
    margin-left: -180px;
  }

  @media (max-width: 714px) {
    .flex-mode {
      display: initial;
      margin-left: -180px;
    }
  }

  @media (max-width: 571px) {
    .flex-mode {
      display: initial;
      margin-left: -100px;
    }
  }

  @media (max-width: 485px) {
    .flex-mode {
      display: initial;
      margin-left: 0px;
    }
  }
</style>

<script>
  import { updateSession, getPrice, getRegion } from "../../utils.js";
  import { goto } from "@roxi/routify";

  import CountryName from "../../components/CountryName.svelte";
  import CurrencyConverter from "../../components/CurrencyConverter.svelte";
  import ProductSelect from "../../components/ProductSelect.svelte";
  import ContactUsForm from "../../components/ContactUsForm.svelte";
  import ConsentModal from "../../components/ConsentModal.svelte";
  import { _ } from "svelte-i18n";
  import FreePriceDisplay from "../../components/FreePriceDisplay.svelte";

  export let scoped;
  const {
    countries,
    symbols,
    rates,
    prices,
    session,
    countryCode,
    countryCodeIsSelected,
  } = scoped;
  let isOpen = false;

  const region = getRegion(countries, countryCode);
  const regionalPrices = prices.filter((price) => price.region.code === region);
  const initialPrice = getPrice(regionalPrices, $session.selectedPriceID);

  let product;
  product = initialPrice ? initialPrice.variant.product : null;

  let pricesForProduct;
  $: pricesForProduct = product
    ? regionalPrices.filter((price) => price.variant.product.id === product.id)
    : [];

  let price;
  $: price = pricesForProduct ? pricesForProduct[0] : null;

  let toggleCurrencyConverter;

  const productDetails = {
    touchstone_digital: {
      description: "Touchstone Digital PDF Annual Subscription",
      blurb: `
        Annual subscription to the digital edition of the Touchstone
        magazine, delivered by email with a link to the PDF file, which you may print
        out yourself.  This is the best option to receive Touchstone hot off the press
        the moment it is released, including future enhancements (eg.  colour!).
        Available to all members not currently on a course receiving Gwersi.
      `,
    },
    touchstone_printed: {
      description: "Touchstone Printed Booklet Annual Subscription",
      blurb: `
        Annual subscription to the (B/W) printed booklet edition of the
        Touchstone magazine, delivered by postal mail.  Please note that
        extended delivery times may apply.  Available to all members not
        currently on a course receiving Gwersi.
      `,
    },
  };

  let isConsentGiven = false;

  function formSubmit() {
    const consentModal = new ConsentModal({
      target: document.body,
      props: {
        isOpen: true,
      },
    });
    consentModal.$on("consent", (event) => {
      isConsentGiven = event.detail.agreed;
      consentModal.$destroy();
      if (isConsentGiven) {
        updateSession(session, {
          selectedPriceID: price.id,
          selectedPriceRegionCode: price.region.code,
          isConsentGiven: isConsentGiven,
        });
        $goto("./details");
      }
    });
  }
</script>

<form on:submit|preventDefault={formSubmit}>
  <section>
    <h2>Which format do you prefer ?</h2>
    <ProductSelect
      prices={regionalPrices}
      details={productDetails}
      order={["touchstone_digital", "touchstone_printed"]}
      bind:product
    />
  </section>

  <section class="links">
    <div class="change-location">
      <p>
        All our prices in GBP based on your
        {#if countryCodeIsSelected}
          selected
        {:else}
          detected
        {/if}
        location in: <CountryName {countries} {countryCode} />
      </p>
      <a href="/touchstone/country">&gt; Change my location</a>
    </div>

    {#if price}
      <div class="currency-converter">
        <p>Click here to see this price in another currency.</p>
        <button on:click|preventDefault={toggleCurrencyConverter}
          >Currency Converter</button
        >
      </div>
    {/if}

    <button
      class="open"
      on:click|preventDefault={() => {
        isOpen = true;
      }}>> Feedback / Problems? Contact us</button
    >
  </section>

  <CurrencyConverter
    bind:toggle={toggleCurrencyConverter}
    {countries}
    {symbols}
    {rates}
    {price}
    {countryCode}
  />

  <ContactUsForm bind:isOpen />

  <section class="btn-container">
    {#if price}
      <h2 class="total-price-title">{$_("choice.total_price_of_selection")}</h2>
    {/if}
    <div class="btn-container__inside">
      {#if price}
        <FreePriceDisplay {price} />
      {/if}
      <button disabled={!price} class="btn btn--primary">Next</button>
    </div>
  </section>
</form>

<style>
  @import "../../styles/variables.css";

  .links {
    margin-top: 2em;
  }

  .change-location,
  .currency-converter {
    padding: 0.5em 0;
    font-style: italic;
    font-size: 12px;
  }

  .currency-converter button {
    border: 0;
    font: inherit;
    color: inherit;
    background: inherit;
    letter-spacing: 1px;
    color: var(--primary-light);
  }

  .currency-converter button::before {
    content: "> ";
  }

  .currency-converter button:hover {
    cursor: pointer;
  }

  .open {
    margin-bottom: var(--baseline);
    border: 0;
    font: inherit;
    color: inherit;
    background: inherit;
    letter-spacing: 1px;
    color: var(--primary-light);
    padding: 0.5em 0;
    font-style: italic;
    font-size: 12px;
    cursor: pointer;
  }

  .total-price-title {
    color: var(--primary);
    font-size: 1.5rem;
    font-weight: bold;
    padding-bottom: unset;
    padding-right: 360px;
  }

  @media (max-width: 718px) {
    .total-price-title {
      margin-left: 10px;
      padding-right: 0px;
    }
  }
</style>
